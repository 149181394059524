const data = [
  {
    coverImage: "isabel_steva_colita.jpg",
    coverText: "isabel_steva_colita.svg",
    menu: [
      { title: "La facciata del Collegio degli Architetti", video: "1.mp4" },
      { title: "Il rapporto con i collaboratori", video: "2.mp4" }
    ]
  }
]

// 0: Joan Punyet Miro
// 1: Rosa Maria Malet
// 2: Joan Baixas
// 3: Isabel Steva Colita
export const DATA = data[0];
